<template>
  <div class="py-16">
    <div class="container-sm">
      <h2 class="text-center text-3xl sm:text-5xl mb-5 leading-[120%] text-primary">
        Das Erfolgstool<br />für das Handwerk von heute.
      </h2>
      <p class="max-w-5xl mx-auto block text-center text-2xl leading-[125%] mb-20">
        Erhalte <b class="font-semibold">passgenaue Kundenanfragen</b> mit Budget, steuere diese
        Anfragen über unsere Planungsplattform und schaffe durch standardisierte Prozesse die Basis
        für nachhaltiges Wachstum.
      </p>
      <div class="aspect-video">
        <!--
        <iframe
          class="w-full h-full"
          src="https://www.youtube.com/embed/ygFruhygyiM?si=FzmCkcabeJiswRgS"
          title="Sanierungsauftrag in 3 Minuten"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>-->

        <video class="w-full h-full hover:cursor-pointer" controls poster="/images/poster.png">
          <source
            src="https://static.moreathome.de/videos/sanierungsauftrag.mp4"
            type="video/mp4"
            class="hover:cursor-pointer"
          />
        </video>
      </div>
    </div>
  </div>
</template>
