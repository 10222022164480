<template>
  <div class="mt-5">
    <div class="grid grid-cols-2 md:grid-cols-5 divide-y md:divide-y-0">
      <NuxtImg
        sizes="100vw sm:50vw md:120px"
        class="w-full h-auto px-5 py-4"
        loading="lazy"
        src="/images/partner/grohe.png"
      />
      <NuxtImg
        sizes="100vw sm:50vw md:120px"
        class="w-full h-auto px-5 py-4"
        loading="lazy"
        src="/images/partner/brillux.png"
      />
      <NuxtImg
        sizes="100vw sm:50vw md:120px"
        class="w-full h-auto px-5 py-4"
        loading="lazy"
        src="/images/partner/brotje.png"
      />
      <NuxtImg
        sizes="100vw sm:50vw md:120px"
        class="w-full h-auto px-5 py-4"
        loading="lazy"
        src="/images/partner/duravit.png"
      />
      <NuxtImg
        sizes="100vw sm:50vw md:120px"
        class="w-full h-auto px-5 py-4"
        loading="lazy"
        src="/images/partner/farrow.png"
      />
    </div>
  </div>
</template>
