<template>
  <div class="md:h-70 grid gap-y-10 sm:gap-x-20 md:grid-cols-3">
    <div
      class="flex flex-col items-center gap-10 rounded-2xl border-2 border-action bg-primary py-10 text-white px-1"
    >
      <h3 class="text-center text-h3-mobile sm:text-h3-desktop">Handwerker deutschlandweit</h3>
      <h1 class="text-center text-h1-mobile font-bold text-action sm:text-h2-desktop">
        <AnimatedNumber :value="statistics.totalTeams" format="number" />
      </h1>
    </div>
    <div
      class="flex flex-col items-center gap-10 rounded-2xl border-2 border-action bg-primary py-10 text-white"
    >
      <h3 class="text-center text-h3-mobile sm:text-h3-desktop">Vermitteltes Sanierungsvolumen</h3>
      <h1 class="text-center text-h1-mobile font-bold text-action sm:text-h2-desktop">
        <AnimatedNumber :value="statistics.totalVolume / 100" format="currency" />
      </h1>
    </div>
    <div
      class="flex flex-col items-center gap-10 rounded-2xl border-2 border-action bg-primary py-10 text-white"
    >
      <h3 class="text-center text-h3-mobile sm:text-h3-desktop">
        Vermittelte <br />
        Sanierungen
      </h3>
      <h1 class="text-center text-h1-mobile font-bold text-action sm:text-h2-desktop">
        <AnimatedNumber :value="statistics.totalContacts" format="number" />
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

import AnimatedNumber from '../AnimatedNumber.vue';
interface Statistics {
  totalTeams: number;
  totalVolume: number;
  totalContacts: number;
}

export default defineComponent({
  name: 'ThreeBoxes',
  components: {
    AnimatedNumber,
  },
  setup() {
    const statistics = ref<Statistics>({
      totalTeams: 0,
      totalVolume: 0,
      totalContacts: 0,
    });

    function isStatistics(data: unknown): data is Statistics {
      return (
        typeof data === 'object' &&
        data !== null &&
        'totalTeams' in data &&
        'totalVolume' in data &&
        'totalContacts' in data
      );
    }

    onMounted(() => {
      fetch('https://app.moreathome.de/api/statistics')
        .then((response) => response.json())
        .then((data) => {
          if (isStatistics(data)) {
            statistics.value = data;
          } else {
            throw new Error('Invalid data format');
          }
        })
        .catch((error) => console.error('Error fetching statistics:', error));
    });

    return {
      statistics,
    };
  },
});
</script>
