<script setup>
import anime from 'animejs';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  duration: {
    type: Number,
    default: 1000,
  },
  formatter: {
    type: Function,
    default: (value) => {
      return value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    },
  },
});

const count = ref(props.modelValue);

const euro = (value) => {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

const setCount = (val) => {
  const obj = { n: count.value };
  anime({
    targets: obj,
    n: val,
    duration: props.duration,
    easing: 'linear',
    update: () => {
      count.value = obj.n;
    },
  });
};

watch(
  () => props.modelValue,
  (val) => {
    setCount(val);
  }
);
</script>
<template>
  <span>{{ formatter(count) }}</span>
</template>
