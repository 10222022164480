<script setup>
const rooms = ref([
  {
    name: 'Einfamilienhaus',
    subtitle: 'Sanierung, 4 Zimmer',
    total: 0,
    min: 45_000,
    max: 95_000,
  },
  {
    name: 'Eigentumswohnung',
    subtitle: 'Badsanierung',
    total: 0,
    min: 22_000,
    max: 40_000,
  },
  {
    name: 'Doppelhaushälfte',
    subtitle: 'Kernsanierung',
    total: 0,
    min: 120_000,
    max: 220_000,
  },
  {
    name: 'Altbauwohnung',
    subtitle: 'Sanierung, 5 Zimmer',
    total: 0,
    min: 65_000,
    max: 140_000,
  },
]);

onMounted(() => {
  rooms.value = rooms.value.map((room) => {
    return {
      ...room,
      total: Math.floor(Math.random() * (room.max - room.min + 1) + room.min),
    };
  });
});
</script>
<template>
  <div class="pt-20 relative overflow-hidden">
    <NuxtImg
      width="2000"
      sizes="20vw sm:500px md:1300px"
      src="/images/bg/werkzeug-sanierung.png"
      class="absolute inset-0 object-cover object-center h-full w-full hidden sm:block"
      alt="Handwerker"
    />

    <NuxtImg
      width="2000"
      preload
      sizes="500px md:1300px"
      src="/images/bg/werkzeug-sanierung.png"
      class="object-cover object-center h-full w-full sm:hidden -mb-16 -mt-16"
      alt="Handwerker"
    />

    <div class="container">
      <div class="flex justify-end items-start relative h-full">
        <div class="p-5 sm:p-7 rounded-t-2xl z-10 bg-primary text-white md:max-w-[500px]">
          <h1 class="font-extrabold text-4xl text-white mt-0 leading-[120%]">
            Dein Weg zu mehr Kundenanfragen und höheren Umsätzen!
          </h1>

          <h2 class="text-xl font-normal my-4 leading-[120%] max-w-[450px]">
            Wir haben die Kunden, die wirklich sanieren wollen!
          </h2>

          <div class="divide-y divide-gray-300 space-y-1">
            <div v-for="r in rooms" class="pt-1 flex">
              <div class="flex flex-col">
                <span class="text-xl">{{ r.name }}</span>
                <span class="text-gray-400 -mt-1 text-sm" v-html="r.subtitle" />
              </div>
              <span class="ml-auto text-2xl font-semibold">
                <UiInputsAnimatedNumber v-model="r.total" />
              </span>
            </div>
            <div class="text-right text-3xl font-bold text-action">
              <UiInputsAnimatedNumber :model-value="rooms.reduce((a, b) => a + b.total, 0)" />
            </div>
          </div>
          <p class="text-sm pt-5">
            Jetzt
            <span class="font-semibold">kostenlos anmelden und auf dich zugeschnittene</span>
            Anfragen erhalten.
          </p>

          <NuxtLink to="/anmelden" class="rounded-xl mt-5 btn w-full btn--action md:text-xl">
            Jetzt Sanierungsanfragen gewinnen
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
